import React, { useState } from "react";

import SettingsLayout from "../components/layouts/SettingsLayout";
import ProjectSettings from "../components/settings/ProjectSettings";
import PageTitle from "../components/common/PageTitle";

function Settings() {
  return (
    <SettingsLayout>
      <PageTitle page="Settings" />
      <ProjectSettings />
    </SettingsLayout>
  );
}

export default Settings;
